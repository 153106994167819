<template>
    <div>
        <!-- 图表标题 -->
        <div class="analyPageTop">
            <div class="PageTopBox">
                <div class="topBoxProName">{{topString}}</div>
                <div v-if="activeIndex == '1'">{{topTimer}}</div>
            </div>
        </div>
        <!-- 图表 -->
        <div class="analyPageTop">
            <div class="PageEcharts">
                <div class="echartsBox">
                    <div class="echartMenu">
                        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                            <el-menu-item index="1">累计数量</el-menu-item>
                            <el-menu-item index="2">趋势分析</el-menu-item>
                        </el-menu>
                    </div>
                    <div class="timerPick" v-if="activeIndex == '2'">
                        <el-date-picker
                            v-model="chartsTimers"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            @change="selectTimers"
                            value-format="yyyy-MM-dd"
                            :picker-options="{
                                disabledDate: (time) => {
                                    return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365  || time.getTime() > Date.now() - 3600 * 1000 * 24
                                }
                            }"
                        >
                        </el-date-picker>
                    </div>
                </div>
                <div v-if="isHiddenMap" id="CumulativeQuantity" class="echartsView"></div>
            </div>
        </div>
        <!-- 列表 -->
        <div class="viewArea" v-if="activeIndex == '1'">
            <el-table
                :data="addupTableData"
                border
                style="width: 88%;margin: 0 auto;"
                v-loading="listLoading"
                @current-change='currentChange'
                :highlight-current-row='true'
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="RegionName" label="辖区" min-width="100" align="center">
                    <template scope="scope">
                        <el-button @click="getAddupDataList(scope.row)" type="text">{{scope.row.RegionName}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="RegisterNumber" label="注册数量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="ExamEnrollNumber" label="报考数量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="CerNumber" label="持证数量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="TrainNumber" label="参训数量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="NumOfGiveUpEnroll" label="放弃报考数量" min-width="130" align="center"></el-table-column>
                <el-table-column prop="NumOfExamNoTrain" label="申请免培数量" min-width="130" align="center"></el-table-column>
                <el-table-column prop="RateOfCer" label="持证率" min-width="90" align="center">
                    <template scope="scope">
                        {{formatRightRatio(scope.row.RateOfCer) > 0 ? formatRightRatio(scope.row.RateOfCer) + '‱' : 0}}
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="viewArea" v-if="activeIndex == '2'">
            <div class="areaBox" v-if="isShowPro">
                <el-menu :default-active="proActive" @select="proSelect" class="el-menu-demo" mode="horizontal">
                    <el-menu-item index="1919">广东省</el-menu-item>
                </el-menu>
            </div>
            <div class="areaBox" v-if="isShowcity">
                <el-menu :default-active="cityActive" @select="citySelect" class="el-menu-demo" mode="horizontal">
                    <el-menu-item v-for="(item,index) in cityArr" :index="String(item.ID)" :key="index">{{item.Name}}</el-menu-item>
                    <el-menu-item v-if="CityID == ''" index="888888">本级</el-menu-item>
                    <el-menu-item v-if="CityID == ''" index="999999">待岗</el-menu-item>
                </el-menu>
            </div>
            <div class="areaBox" v-if="RegionArr.length > 0">
                <el-menu :default-active="regionActive" @select="regionSelect" class="el-menu-demo" mode="horizontal">
                    <el-menu-item v-for="(item,index) in RegionArr" :index="String(item.ID)" :key="index">{{item.Name}}</el-menu-item>
                </el-menu>
            </div>
            <el-table
                :data="trendTableData"
                border
                style="width: 88%;margin: 0 auto;"
                v-loading="listLoading"
                @current-change='currentChange'
                :highlight-current-row='true'
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
                <el-table-column prop="TargetDate" label="日期" min-width="100" align="center"></el-table-column>
                <el-table-column prop="AccountCount" label="注册数量" min-width="100" align="center">
                    <template scope="scope">
                        <el-button @click="getRegisterDataList(scope.row)" type="text">{{scope.row.AccountCount}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="EnrollCount" label="报考数量" min-width="100" align="center">
                    <template scope="scope">
                        <el-button @click="getReportDataList(scope.row)" type="text">{{scope.row.EnrollCount}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="AttendExamCount" label="参加考试数量" min-width="120" align="center"></el-table-column>
                <el-table-column prop="CertificateCount" label="持证数量" min-width="100" align="center">
                    <template scope="scope">
                        <el-button @click="getHoldcertificateDataList(scope.row)" type="text">{{scope.row.CertificateCount}}</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="TrainCount" label="参训数量" min-width="100" align="center"></el-table-column>
                <el-table-column prop="AbandonEnrollCount" label="放弃报考数量" min-width="130" align="center"></el-table-column>
                <el-table-column prop="ApplyFreeTrainCount" label="申请免培数量" min-width="130" align="center"></el-table-column>
                <!-- <el-table-column prop="ApplyFreeTrainCount" label="持证率" min-width="90" align="center">
                    <template scope="scope">
                        {{formatRightRatio(scope.row.CertificateRate) > 0 ? formatRightRatio(scope.row.CertificateRate) + '‱' : scope.row.TargetDate == '合计' ? '-' : 0}}
                    </template>
                </el-table-column> -->
            </el-table>
            <el-dialog
                :title="dialogType == 1 ? '注册数量' : (dialogType == 2) ? '报考数量' : '持证数量'"
                :visible.sync="dataDetailsDialog"
                width="60%"
                :close-on-click-modal='true'
            >
                <el-table
                    v-loading='dialogLoading'
                    v-if="dialogType == 1"
                    :data="dialogTable"
                    border
                    highlight-current-row
                    :cell-style="rowClass"
                >   
                    <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                    <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="PhoneNumber" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="UnitName" label="所在单位" align="center"></el-table-column>
                    <el-table-column prop="Date" label="创建时间" align="center"></el-table-column>
                </el-table>
                <el-table
                    v-loading='dialogLoading'
                    v-if="dialogType == 2"
                    :data="dialogTable"
                    border
                    highlight-current-row
                >   
                    <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                    <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="PhoneNumber" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="UnitName" label="所在单位" align="center"></el-table-column>
                    <el-table-column prop="StationName" label="岗位名称" align="center"></el-table-column>
                    <el-table-column prop="TradeName" label="行业名称" align="center"></el-table-column>
                    <el-table-column prop="Date" label="报考时间" align="center"></el-table-column>
                    <el-table-column prop="Status" label="考试状态" align="center">
                        <template slot-scope='scope'>
                            <el-tag type='success' v-if='scope.row.Status == 1'>正常</el-tag>
                            <el-tag type='danger' v-else>放弃</el-tag>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                    v-loading='dialogLoading'
                    v-if="dialogType == 3"
                    :data="dialogTable"
                    border
                    highlight-current-row
                    :cell-style="rowClass"
                >   
                    <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                    <el-table-column prop="Name" label="姓名" align="center"></el-table-column>
                    <el-table-column prop="PhoneNumber" label="手机号" align="center"></el-table-column>
                    <el-table-column prop="UnitName" label="当前登记单位" align="center"></el-table-column>
                    <el-table-column prop="StationName" label="岗位名称" align="center"></el-table-column>
                    <el-table-column prop="TradeName" label="行业名称" align="center"></el-table-column>
                    <el-table-column prop="Date" label="发证时间" align="center"></el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.PageIndex"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pages.PageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.DataCount"
                    class='pageBar'
                >
                </el-pagination>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dataDetailsDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
var echarts = require('echarts');
import util from "../../../util/date";
import { getStatisticalOfAccumulatedQuantity,trendAnalysisDataNew,getAreaDictionaryList,getStudentAccountDetailByDate,getStudentExamEnrollDetailByDate,getStudentCertificateDetailByDate,trendAnalysisDataOfCertificateRate } from '@/api/api'
export default {
    data() {
        return {
            // 图表
            topString: "广东省",
            topTimer: "",
            chartsTimers: [],
            activeIndex: "1",
            isHiddenMap: true,
            addupObj: {
                addupNameArr: [],
                registerNumberArr: [],
                examEnrollNumberArr: [],
                rateOfCerArr: [],
                cerNumberArr: [],
            },
            TrendObj: {
                addupNameArr: [],
                registerNumberArr: [],
                examEnrollNumberArr: [],
                attendExamCountArr: [],
                rateOfCerArr: [],
                cerNumberArr: [],
            },
            // 列表
            proActive: "1919",
            cityActive: "",
            regionActive: "",
            addupTableData: [],
            trendTableData: [],
            listLoading: false,
            ProvinceID: 1919,
            CityID: '',
            RegionID: '',
            TownID: '',
            cityArr: [],
            RegionArr: [],
            isShowPro: true,
            isShowcity: true,
            // 注册数量、报考数量、持证数量弹窗
            dataDetailsDialog: false,
            dialogType: 1,
            dialogLoading: false,
            dialogTable: [],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            newCityID: "",
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 获取持证人数数据
        getAddupDataList(val){
            if(val && val.RegionName == "待岗") {
                return
            }
            this.listLoading = true
            var params = {
                ProvinceID: 1919,
                CityID: val ? val.CityID : "",
                RegionID: val ? val.RegionID : "",
                TownID: val ? val.TownID : "",
            }
            this.CityID = val ? val.CityID : "",
            this.RegionID = val ? val.RegionID : "",
            this.TownID = val ? val.TownID : "",
            getStatisticalOfAccumulatedQuantity(params).then(res => {
                var result = res.data
                if(result.Success){
                    this.topString = result.Response.CurRegionData.RegionName
                    var userInfoData = JSON.parse(localStorage.getItem("user"))
                    if(val && val.TownID && !userInfoData.TownID) {
                        result.Response.CurRegionData.TownID = null
                        result.Response.CurRegionData.RegionID = val.RegionID
                        result.Response.CurRegionData.CityID = val.CityID
                        result.Response.CurRegionData.ProvinceID = 1919
                    } else if(val && val.RegionID && !userInfoData.RegionID) {
                        result.Response.CurRegionData.TownID = null
                        result.Response.CurRegionData.RegionID = null
                        result.Response.CurRegionData.CityID = val.CityID
                        result.Response.CurRegionData.ProvinceID = 1919
                    } else if(val && val.CityID && !userInfoData.CityID) {
                        result.Response.CurRegionData.TownID = null
                        result.Response.CurRegionData.RegionID = null
                        result.Response.CurRegionData.CityID = null
                        result.Response.CurRegionData.ProvinceID = 1919
                    } else {
                        result.Response.CurRegionData.ProvinceID = 1919
                    }
                    result.Response.NextRegionData.unshift(result.Response.CurRegionData)
                    this.addupTableData = result.Response.NextRegionData
                    this.addupObj = {
                        addupNameArr: [],
                        registerNumberArr: [],
                        examEnrollNumberArr: [],
                        rateOfCerArr: [],
                        cerNumberArr: [],
                    },
                    this.addupTableData.forEach((item,index) => {
                        if(index > 0 && item.RegionName != "本级" && item.RegionName != "待岗") {
                            this.addupObj.addupNameArr.push(item.RegionName)
                            this.addupObj.registerNumberArr.push(item.RegisterNumber)
                            this.addupObj.examEnrollNumberArr.push(item.ExamEnrollNumber)
                            this.addupObj.rateOfCerArr.push(this.formatRightRatio(item.RateOfCer))
                            this.addupObj.cerNumberArr.push(item.CerNumber)
                        }
                    })
                    this.setQuantityEcharts()
                }
                this.listLoading = false
                if(this.RegionID) {
                    this.cityArr = []
                    this.RegionArr = []
                    this.cityActive = ""
                    this.regionActive = String(this.RegionID)
                    var newObj = {
                        ID: this.RegionID,
                        Name: result.Response.CurRegionData.RegionName,
                    }
                    this.isShowPro = false
                    this.isShowcity = false
                    this.RegionArr.push(newObj)
                } else if(this.CityID) {
                    this.cityArr = []
                    this.cityActive = String(this.CityID)
                    var newObj = {
                        ID: this.CityID,
                        Name: result.Response.CurRegionData.RegionName,
                    }
                    this.isShowcity = true
                    this.isShowPro = false
                    this.cityArr.push(newObj)
                    this.getRegionData(this.CityID)
                } else {
                    this.RegionArr = []
                    this.cityActive = ""
                    this.regionActive = ""
                    this.isShowcity = true
                    this.isShowPro = true
                    this.getCityData();
                }
            })
        },
        // 获取持证率数据
        getTrendDataList(val){
            var that = this
            this.listLoading = true
            var params = {
                provinceID: this.ProvinceID,
                cityID: (this.CityID == 888888 || this.CityID == 999999) ? "" : this.CityID,
                regionID: this.RegionID == 777777 ? "" : this.RegionID,
                townID: this.TownID,
                startDate: this.formatCreateTimeDay(this.chartsTimers[0]),
                endDate: this.formatCreateTimeDay(this.chartsTimers[1]),
            }
            trendAnalysisDataNew(params).then(res => {
                var result = res.data
                if(result.Success){
                    if(val == 888888 || val == 777777) {
                        this.trendTableData = result.Response.CurrentLevelData
                    } else if(val == 999999) {
                        this.trendTableData = result.Response.JobWaitData
                    } else {
                        this.trendTableData = result.Response.SubordinateData
                    }
                    var lineObj= {
                        TargetDate: "合计",
                        AccountCount: 0,
                        EnrollCount: 0,
                        AttendExamCount: 0,
                        CertificateCount: 0,
                        TrainCount: 0,
                        AbandonEnrollCount: 0,
                        ApplyFreeTrainCount: 0,
                    }
                    this.trendTableData.forEach((item,index) => {
                        lineObj.AccountCount += item.AccountCount
                        lineObj.EnrollCount += item.EnrollCount
                        lineObj.AttendExamCount += item.AttendExamCount
                        lineObj.CertificateCount += item.CertificateCount
                        lineObj.TrainCount += item.TrainCount
                        lineObj.AbandonEnrollCount += item.AbandonEnrollCount
                        lineObj.ApplyFreeTrainCount += item.ApplyFreeTrainCount
                    })
                    setTimeout(() => {
                        that.trendTableData.unshift(lineObj)
                    });
                }
                this.listLoading = false
            })
        },
        // 获取持证率数据
        getTrendCertificateDataList(val){
            var params = {
                provinceID: this.ProvinceID,
                cityID: (this.CityID == 888888 || this.CityID == 999999) ? "" : this.CityID,
                regionID: this.RegionID == 777777 ? "" : this.RegionID,
                townID: this.TownID,
                // startDate: !(Date.parse(this.chartsTimers[0])<(Date.now() - 3600 * 1000 * 24 * 8)) ? this.formatCreateTimeDay(this.chartsTimers[0]) : this.formatCreateTimeDay(Date.now() - (3600 * 1000 * 24 * 7)),
                // endDate: !(Date.parse(this.chartsTimers[1])<(Date.now() - 3600 * 1000 * 24 * 8)) ? this.formatCreateTimeDay(this.chartsTimers[1]) : this.formatCreateTimeDay(Date.now() - (3600 * 1000 * 24)),
                startDate: !(Date.parse(this.chartsTimers[0])>(Date.parse(this.chartsTimers[1]) - 3600 * 1000 * 24 * 7)) ? this.formatCreateTimeDay(Date.parse(this.chartsTimers[1]) - 3600 * 1000 * 24 * 6) : this.formatCreateTimeDay(this.chartsTimers[0]),
                endDate: this.formatCreateTimeDay(this.chartsTimers[1]),
            }
            trendAnalysisDataOfCertificateRate(params).then(res => {
                var result = res.data
                if(result.Success){
                    var trendTableData = []
                    if(val == 888888 || val == 777777) {
                        trendTableData = result.Response.CurrentLevelData
                    } else if(val == 999999) {
                        trendTableData = result.Response.JobWaitData
                    } else {
                        trendTableData = result.Response.SubordinateData
                    }
                    this.TrendObj = {
                        addupNameArr: [],
                        registerNumberArr: [],
                        examEnrollNumberArr: [],
                        attendExamCountArr: [],
                        rateOfCerArr: [],
                        cerNumberArr: [],
                    }
                    trendTableData.forEach((item,index) => {
                        this.TrendObj.addupNameArr.push(item.TargetDate)
                        this.TrendObj.registerNumberArr.push(item.AccountCount)
                        this.TrendObj.examEnrollNumberArr.push(item.EnrollCount)
                        this.TrendObj.attendExamCountArr.push(item.AttendExamCount)
                        this.TrendObj.rateOfCerArr.push(this.formatRightRatio(item.CertificateRate))
                        this.TrendObj.cerNumberArr.push(item.CertificateCount)
                    })
                    this.setTrendEcharts()
                }
            })
        },
        // 获取注册数量
        getRegisterDataList(row) {
            this.dialogType = 1
            this.needRow = row
            this.getDialogTableFun(this.dialogType)
        },
        // 获取报考数量
        getReportDataList(row) {
            this.dialogType = 2
            this.needRow = row
            this.getDialogTableFun(this.dialogType)
        },
        // 获取持证数量
        getHoldcertificateDataList(row) {
            this.dialogType = 3
            this.needRow = row
            this.getDialogTableFun(this.dialogType)
        },
        getDialogTableFun(val) {
            this.dialogLoading = false
            this.dialogTable = []
            this.dataDetailsDialog = true
            var getTableDataFun = getStudentAccountDetailByDate
            if(val == 2) {
                getTableDataFun = getStudentExamEnrollDetailByDate
            } else if(val == 3) {
                getTableDataFun = getStudentCertificateDetailByDate
            }
            var params = {
                provinceID: this.ProvinceID,
                cityID: (this.CityID == 888888 || this.CityID == 999999) ? "" : this.CityID,
                regionID: this.RegionID == 777777 ? "" : this.RegionID,
                townID: this.TownID,
                startDate: this.formatCreateTimeDay(this.chartsTimers[0]),
                endDate: this.formatCreateTimeDay(this.chartsTimers[1]),
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize,
                isCurrentLevel: false,
                isWaitJob: false,
            }
            // if(this.needRow.TargetDate != "合计") {
            //     params.startDate = this.formatCreateTimeDay(this.chartsTimers[0]).split("-")[0] + '-' + this.needRow.TargetDate
            //     params.endDate = this.formatCreateTimeDay(this.chartsTimers[0]).split("-")[0] + '-' + this.needRow.TargetDate
            // }
            if(this.needRow.TargetDate != "合计") {
                params.startDate = this.needRow.TargetDate
                params.endDate = this.needRow.TargetDate
            }
            if(this.newCityID == 888888 || this.RegionID == 777777) { //本级
                params.isCurrentLevel = true
            } else if(this.newCityID == 999999) { //待岗
                params.isWaitJob = true
            }
            getTableDataFun(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.dialogTable = result.Response.Data
                    this.pages.DataCount = result.Response.DataCount
                } else {
                    this.$message.error(result.Message || "加载失败！")
                }
                this.dialogLoading = false
            })
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getDialogTableFun(this.dialogType)
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getDialogTableFun(this.dialogType)
        },
        // 查询
        getAnalysisListData() {
            this.getAddupDataList();
        },
        // 点击table某一行
        currentChange(val) {
            
        },
        // 省市区切换
        proSelect(val) {
            this.getCityData()
            this.RegionArr = []
            this.CityID = ""
            this.RegionID = ""
            this.TownID = ""
            this.cityActive = ""
            var that = this
            setTimeout(() => {
                that.getTrendDataList()
                that.getTrendCertificateDataList()
            });
        },
        citySelect(val) {
            this.getRegionData(val)
            this.newCityID = val
            this.CityID = (val == 888888 || val == 999999) ? '' : val
            this.RegionID = ""
            this.TownID = ""
            var that = this
            setTimeout(() => {
                that.getTrendDataList(val)
                that.getTrendCertificateDataList(val)
            });
        },
        regionSelect(val) {
            this.RegionID = val
            this.TownID = ""
            var that = this
            setTimeout(() => {
                that.getTrendDataList(val)
                that.getTrendCertificateDataList(val)
            });
        },
        // 日期选择
        selectTimers(val) {
            var that = this
            setTimeout(() => {
                that.getTrendDataList()
                that.getTrendCertificateDataList()
            });
        },
        // 图表切换
        handleSelect(val) {
            var that = this
            this.activeIndex = val
            this.isHiddenMap = false
            setTimeout(() => {
                that.isHiddenMap = true
                setTimeout(() => {
                    if(val == '1') {
                        that.setQuantityEcharts()
                    } else {
                        that.getTrendDataList()
                        that.getTrendCertificateDataList()
                    }
                });
            });
        },
        setQuantityEcharts() {
            var QuantityChart = document.getElementById('CumulativeQuantity');
            var myChart = echarts.init(QuantityChart);
            var option;
            const colors = ['#5470C6', '#91CC75', '#EE6666', '#dd73ee'];
            option = {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    right: '10%'
                },
                legend: {
                    // data: ['注册数量', '报考数量', '持证数量', '持证率']
                    data: ['报考数量', '持证数量', '持证率']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: this.addupObj.addupNameArr
                    }
                ],
                yAxis: [
                    // {
                    //     type: 'value',
                    //     name: '注册数量',
                    //     position: 'right',
                    //     alignTicks: true,
                    //     axisLine: {
                    //         show: false,
                    //         lineStyle: {
                    //             color: colors[0]
                    //         }
                    //     },
                    //     axisLabel: {
                    //         formatter: '{value} '
                    //     }
                    // },
                    {
                        type: 'value',
                        name: '报考数量',
                        position: 'right',
                        alignTicks: true,
                        // offset: 80,
                        show: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[1]
                            }
                        },
                        axisLabel: {
                            formatter: '{value} '
                        }
                    },
                    {
                        type: 'value',
                        name: '持证数量',
                        position: 'right',
                        alignTicks: true,
                        offset: 80,
                        show: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[1]
                            }
                        },
                        axisLabel: {
                            formatter: '{value} '
                        }
                    },
                    {
                        type: 'value',
                        name: '持证率',
                        position: 'left',
                        alignTicks: true,
                        min: '0',
                        // max: '100',
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[2]
                            }
                        },
                        axisLabel: {
                            formatter: '{value} ‱'
                        }
                    }
                ],
                series: [
                    // {
                    //     name: '注册数量',
                    //     type: 'bar',
                    //     data: this.addupObj.registerNumberArr
                    // },
                    {
                        name: '报考数量',
                        type: 'bar',
                        // yAxisIndex: 1,
                        data: this.addupObj.examEnrollNumberArr
                    },
                    {
                        name: '持证数量',
                        type: 'bar',
                        // yAxisIndex: 3,
                        data: this.addupObj.cerNumberArr
                    },
                    {
                        name: '持证率',
                        type: 'line',
                        yAxisIndex: 2,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '  ‱';
                            }
                        },
                        data: this.addupObj.rateOfCerArr
                    },
                ]
            };
            option && myChart.setOption(option);
        },
        setTrendEcharts() {
            var QuantityChart = document.getElementById('CumulativeQuantity');
            var myChart = echarts.init(QuantityChart);
            var option;
            const colors = ['#5470C6', '#91CC75', '#EE6666', '#dd73ee'];
            option = {
                color: colors,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross'
                    }
                },
                grid: {
                    right: '10%'
                },
                legend: {
                    data: ['报考数量', '参加考试数量', '持证数量', '持证率']
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true
                        },
                        data: this.TrendObj.addupNameArr.reverse().slice(0, 7).reverse(),
                        // axisPointer: {
                        //     type: 'shadow'
                        // }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '数量',
                        position: 'left',
                        alignTicks: true,
                        min: '0',
                        // interval: 50,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[2]
                            }
                        },
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    {
                        type: 'value',
                        name: '持证率',
                        position: 'right',
                        alignTicks: true,
                        min: 0,
                        // interval: 5,
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: colors[0]
                            }
                        },
                        axisLabel: {
                            formatter: '{value} ‱'
                        }
                    }
                ],
                series: [
                    {
                        name: '报考数量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '';
                            }
                        },
                        data: this.TrendObj.examEnrollNumberArr.reverse().slice(0, 7).reverse()
                    },
                    {
                        name: '参加考试数量',
                        type: 'bar',
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '';
                            }
                        },
                        data: this.TrendObj.attendExamCountArr.reverse().slice(0, 7).reverse()
                    },
                    {
                        name: '持证数量',
                        type: 'bar',
                        data: this.TrendObj.cerNumberArr.reverse().slice(0, 7).reverse()
                    },
                    {
                        name: '持证率',
                        type: 'line',
                        yAxisIndex: 1,
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + ' ‱';
                            }
                        },
                        data: this.TrendObj.rateOfCerArr.reverse().slice(0, 7).reverse()
                    },
                ]
            }
            option && myChart.setOption(option);
        },
        // 获取广东各个市
        getCityData(PId) {
            var params = {
                parentId: PId ? PId : 1919,
            }
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.cityArr = []
                    setTimeout(() => {
                        this.cityArr = result.Response
                    });
                }
            })
        },
        // 获取广东各个区
        getRegionData(val) {
            var params = {
                parentId: val
            }
            getAreaDictionaryList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    if(result.Response.length > 0) {
                        result.Response.push({
                                Name: "本级",
                                ParentId: 888888,
                                Level: 1,
                                ID: 777777,
                            })
                        this.RegionArr = result.Response
                    }
                }
            })
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
        },
        formatCreateTimeDay: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd");
        },
        // 筛选项input框清空
        handleClear(){
            
        },
        getLastSomeDay() {
            var currentDate = new Date();  // 获取当前时间
            var previousDate = new Date(currentDate);  // 复制当前时间对象
            var preSevDate = new Date(currentDate);  // 复制当前时间对象
            previousDate.setDate(currentDate.getDate() - 1);  // 将日期设置为前一天
            preSevDate.setDate(currentDate.getDate() - 7)
            this.chartsTimers = [preSevDate,previousDate]
            this.topTimer = "截止日期 " + this.formatCreateTimeDay(previousDate)
        },
        // 截取数字
		formatRightRatio(val) {
			if(val > 0) {
				// return (val * 100).toFixed(2) + '%'
				var newVal = ((val * 10000).toFixed(2)).slice(0,5).replace('.00', '')
				if(String(newVal).slice(-2) == '.0') {
					return newVal.replace('.0', '')
				} else {
					return newVal
				}
			} else {
				return 0
			}
		},
        formatRightRatio1(val) {
			if(val > 0) {
				// return (val * 100).toFixed(2) + '%'
				var newVal = ((val * 100).toFixed(2)).slice(0,5).replace('.00', '')
				if(String(newVal).slice(-2) == '.0') {
					return newVal.replace('.0', '')
				} else {
					return newVal
				}
			} else {
				return 0
			}
		},
        rowClass({columnIndex}){
            return 'padding:8px 0!important;'
        },
    },
    created(){
        var userInfoData = JSON.parse(localStorage.getItem("user"))
        this.ProvinceID = userInfoData.ProvinceID
        this.CityID = userInfoData.CityID
        this.RegionID = userInfoData.RegionID
        this.TownID = userInfoData.TownID
    },
    mounted() {
        var params = {
            ProvinceID: this.ProvinceID,
            CityID: this.CityID,
            RegionID: this.RegionID,
            TownID: this.TownID,
        }
        this.getAddupDataList(params);
        // this.setQuantityEcharts();
        this.getLastSomeDay();
        if(this.RegionID) {
            // this.getRegionData(this.RegionID)
        } else if(this.CityID) {
            this.getRegionData(this.CityID)
        } else {
            this.getCityData();
        }
    }
}
</script>

<style lang="stylus" scoped>
    .analyPageTop {
        width: 88%;
        margin: 0 auto 20px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        border: 1px solid #E4E7ED;
    }
    .PageTopBox {
        display: flex;
        align-items: center;
        font-size: 14px;
        height: 60px;
    }
    .topBoxProName {
        margin: 0 20px;
        font-size: 20px;
        font-weight: bold;
    }
    .echartsBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .PageEcharts {
        margin-left: 20px;
        width: 100%;
    }
    .timerPick {
        margin-right: 20px;
    }
    .echartMenu {
        width: 193px;
    }
    .echartsView {
        width: 100%;
        margin: 20px auto;
        height: 400px;
    }
    .areaBox {
        width: 88%;
        margin: 0 auto;
    }
</style>